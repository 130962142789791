export const menuItems = [


    {
        id: 1,
        label: "Users",
        isTitle: true,
        adminOnly: true,
    },
    {
        id: 2,
        label: 'Users',
        icon: 'ri-parent-line',
        link: '/users/normal',
        adminOnly: true,
    },
    {
        id: 3,
        label: 'Vendors',
        icon: 'ri-user-line',
        link: '/users/vendor',
        adminOnly: true,
    },
    {
        id: 4,
        label: "Tribes",
        isTitle: true,
        adminOnly: true,
    },
    {
        id: 5,
        label: 'Manage tribes',
        icon: 'ri-font-size-2',
        link: '/tribes',
        adminOnly: true,
    },

    {
        id: 6,
        label: "Programs",
        isTitle: true,
        adminOnly: true,
    },
    {
        id: 7,
        label: 'menuitems.programs.list.program',
        icon: 'ri-pinterest-line',
        link: '/programs/programsList',
        adminOnly: false,
    },


    {
        id: 8,
        isLayout: true,
        adminOnly: false,
    },
    {
        id: 9,
        label: "Booking",
        isTitle: true,
        adminOnly: true,
    },
    {
        id: 10,
        label: 'Manage booking',
        icon: 'ri-reserved-line',
        link: '/booking',
        adminOnly: true,
    },
   

]

