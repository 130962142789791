export const menuItems = [

    {
        id: 1,
        label: 'menuitems.apps.text',
        icon: 'ri-apps-2-line',
   
    },
            {
                id: 2,
                label: 'menuitems.icons.text',
                subItems: [
                    {
                        id: 3,
                        label: 'menuitems.icons.list.remix',
                        link: '/icons/remix'
                    },
                    {
                        id: 4,
                        label: 'menuitems.icons.list.materialdesign',
                        link: '/icons/material-design'
                    },
                    {
                        id: 5,
                        label: 'menuitems.icons.list.dripicons',
                        link: '/icons/dripicons'
                    },
                    {
                        id: 6,
                        label: 'menuitems.icons.list.fontawesome',
                        link: '/icons/font-awesome'
                    }
                ]
            },
            {
        id: 7,
        label: 'menuitems.pages.text',
        icon: 'ri-file-copy-2-line',
        subItems: [
            {
                id: 8,
                label: 'menuitems.authentication.text',
                subItems: [
                    {
                        id: 9,
                        label: 'menuitems.authentication.list.login',
                        link: '/auth/login-1'
                    },
                    {
                        id: 10,
                        label: 'menuitems.authentication.list.register',
                        link: '/auth/register-1'
                    },
                    {
                        id: 11,
                        label: 'menuitems.authentication.list.recoverpwd',
                        link: '/auth/recoverpwd-1'
                    },
                    {
                        id: 12,
                        label: 'menuitems.authentication.list.lockscreen',
                        link: '/auth/lock-screen-1'
                    },
                ]
            },
           
        ]
    }
    
    
]